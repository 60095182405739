import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Aboutus() {
  const { t } = useTranslation();

  return (
    <div className="aboutus">
      <Header />
      <div className="aboutus-con">
        <h1> {t("about_us")} </h1>
        <div className="aboutus-exp-con">
          <div className="left-top"></div>
          <div className="left-bot"></div>
          <div className="right-top"></div>
          <div className="right-bot"></div>
          <p>
            {t("about_us1")} <br />
            <br />
            {t("about_us2")} <br />
            <br />
            {t("about_us3")} <br />
            <br />
            {t("about_us4")} <br />
            <br />
          </p>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Aboutus;
