import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import plane from "../../assets/fleet/tecnamimg.png";

function Tecnam() {
  const { t } = useTranslation();

  return (
    <div className="fleet">
      <Header />
      <div className="fleet-main">
        <h1>tecnam p2008JC</h1>
        <img src={plane} alt="tecnam p2008JC" />
        <p>
          {t("tecnam1")}
          <br />
          <br />
          {t("tecnam2")}
          <br />
          <br />
          {t("tecnam3")}
          <br />
          <br />
          {t("tecnam4")}
          <br />
          <br />
          {t("tecnam5")}
          <br />
          <br />
          {t("tecnam6")}
          <br />
          <br />
          {t("tecnam7")}
          <br />
          <br />
          {t("tecnam8")}
          <br />
          <br />
          {t("tecnam9")}
          <br />
          <br />
          {t("tecnam10")}
          <br />
          <br />
          {t("tecnam11")}
          <br />
          <br />
          {t("tecnam12")}
          <br />
          <br />
          {t("tecnam13")}
          <br />
          <br />
          {t("tecnam14")}
          <br />
          <br />
          {t("tecnam15")}
          <br />
          <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Tecnam;
