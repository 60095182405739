import React from "react";
import { useTranslation } from "react-i18next";

import plane from "../../assets/fleet/seminole.png";
import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Seminole() {
  const { t } = useTranslation();

  return (
    <div className="fleet">
      <Header />
      <div className="fleet-main">
        <h1>pİPER SEMİNOLE PA44</h1>
        <img src={plane} alt="pİPER SEMİNOLE PA44" />
        <p className='TopFeatureFl'>
                    {t("seminole7")} <br />
                    {t("seminole8")} <br />
                    {t("seminole9")} <br />
                    {t("seminole10")} <br />
                    {t("seminole11")} <br />
                </p>
        <p>
          {t("seminole1")}
          <br />
          <br />
          {t("seminole2")}
          <br />
          <br />
          {t("seminole3")}
          <br />
          <br />
          {t("seminole4")}
          <br />
          <br />
          {t("seminole5")}
          <br />
          <br />
          {t("seminole6")}
          <br />
          <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}
export default Seminole;
