import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Director() {
  const { t } = useTranslation();

  return (
    <div className="director">
      <Header />
      <div className="director-con">
        <h1> {t("director")} </h1>
        <div className="director-exp-con">
          <div className="left-top"></div>
          <div className="left-bot"></div>
          <div className="right-top"></div>
          <div className="right-bot"></div>
          <p>
            {t("director1")} <br />
            {t("director2")} <br />
            <br />
            {t("director3")} <br />
            <br />
            {t("director4")} <br />
            <br />
            {t("director5")} <br />
            <br />
            {t("director6")} <br />
            <br />
            {t("director7")} <br />
            <br />
            {t("director8")} <br />
            <br />
          </p>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Director;
