import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import main from "../../assets/services/img/vizyontec.png";
import mainlogo from "../../assets/core/logos/vizyontec.png";

function Maintenance() {
  const { t } = useTranslation();

  return (
    <div className="services">
      <Header />
      <div className="services-main maintenance">
        <div className="services-img-con">
          <img src={main} alt="" />
        </div>
        <div className="services-exp">
          <div className="services-exp-logo">
            <img src={mainlogo} alt="" />
          </div>
          <div className="services-exp-con">
            <div className="services-vafa-header">
              <h1> {t("maintenance")} </h1>
            </div>
            <div className="services-vafa-exp">
              <p>
                {t("main1")}
                <br />
                {t("main2")}
                <br />
                <br />
                <strong>{t("main3")}</strong>
                <br />
                {t("main4")}
                <br />
                <br />
                <strong>{t("main5")}</strong>
                <br />
                {t("main6")}
                <br />
                <br />
                <strong>{t("main7")}</strong>
                <br />
                {t("main8")}
              </p>
            </div>
            <div className="services-card">
              <p>Hasan Pınarönü: {t("maintenance_manager")} </p>
              <a href="mailto: hasan.pinaronu@vizyonhavacilik.com.tr">
                hasan.pinaronu@vizyonhavacilik.com.tr
              </a>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Maintenance;
