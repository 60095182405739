import React from "react";
import { useTranslation } from "react-i18next";

import plane from "../../assets/fleet/ccimg.png";
import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Cc() {
  const { t } = useTranslation();

  return (
    <div className="fleet">
      <Header />
      <div className="fleet-main">
        <h1>CC11 - 160 Carbon Cub SS</h1>
        <img src={plane} alt="CC11 - 160 Carbon Cub SS" />
        <p>
          {t("cc1")}
          <br />
          <br />
          {t("cc2")}
          <br />
          <br />
          {t("cc3")}
          <br />
          <br />
          {t("cc4")}
          <br />
          <br />
          {t("cc5")}
          <br />
          <br />
          {t("cc6")}
          <br />
          <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Cc;
