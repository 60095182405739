import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import linehori from "../../assets/blog/icon/linehori.png";

import youtube from "../../assets/icons/youtube.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";

function Blogcard(props) {
  const { t } = useTranslation();

  return (
    <div className="blog-card">
      <img src={props.img} alt="" />
      <div className="blog-card-head">
        <img src={linehori} alt="" />
        <h1> {props.head} </h1>
      </div>
      <div className="blog-card-cont-date">
        <div className="cont-date-logo">
          <a
            href="https://youtube.com/@vizyon.havacilik?si=YIG2SdH3GAoRRmz_"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/vizyon-havacilik/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://www.instagram.com/vizyonhavacilik/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="" />
          </a>
        </div>
        <div className="cont-date-con">
          <p> {props.date} </p>
        </div>
      </div>
      <div className="blog-card-exp">
        <p> {props.cover} </p>
        <Link to={props.link}>
          <p>{t("continue_reading")}</p>
        </Link>
      </div>
      <div className="blog-card-foot">
        <img src={linehori} alt="" />
        <p>
          {t("editor")} <strong>&nbsp;{props.editor}</strong>{" "}
        </p>
      </div>
    </div>
  );
}

export default Blogcard;
