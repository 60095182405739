import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import camgrup from "../../assets/header/camgrup.png";
import headerlogo from "../../assets/header/vizyonlogo.png";
import menu from "../../assets/header/menu.png";
import close from "../../assets/header/close.png";

function Header() {
  const languages = [
    {
      code: "tr",
      name: "TR",
    },
    {
      code: "en",
      name: "EN",
    },
  ];

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const { t } = useTranslation();

  return (
    <div className="navbar">
      <nav>
        <Link to={"/"}>
          <img className="altinyapim" src={headerlogo} alt="" />
        </Link>
        <div className="navbar-items">
          <Link to={"/"}>
            <p>{t("home")}</p>
          </Link>
          <HashLink to={"/#corp"}>
            <p>{t("corprate")}</p>
          </HashLink>
          <HashLink to={"/#service"}>
            <p>{t("services")}</p>
          </HashLink>
          <HashLink to={"/#fleet"}>
            <p>{t("fleet")}</p>
          </HashLink>
          <HashLink to={"/#contact"}>
            <p>{t("contact")} </p>
          </HashLink>
          <a href="https://vafaaero.com/" target="_blank" rel="noreferrer">
            <p>Vafa Uçuş Okulu</p>
          </a>
          <Link to={"/Graduates"}>
            <p>{t("graduate_header")}</p>
          </Link>
          <Link to={"/blog"}>
            <p>Blog</p>
          </Link>
          <select
            defaultValue={i18next.language}
            className="nav-bar-dropdown"
            onChange={(e) => i18next.changeLanguage(e.target.value)}
          >
            {languages.map(({ code, name }) => (
              <option value={code} key={code}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <select
          defaultValue={i18next.language}
          className="nav-bar-dropdown-mobile"
          onChange={(e) => i18next.changeLanguage(e.target.value)}
        >
          {languages.map(({ code, name }) => (
            <option value={code} key={code}>
              {name}
            </option>
          ))}
        </select>
        <div className="navbar-left-items">
          <div className="navbar-menu-button" onClick={handleClick}>
            <img src={click ? close : menu} alt="" />
          </div>
          <a className="cam" href="https://www.camgrup.org/" target="blank">
            <img src={camgrup} alt="" />
          </a>
        </div>
        <div className="navbar-mobile">
          <nav
            className={
              click ? "nav-bar-mobile-items" : "nav-bar-mobile-items-close"
            }
          >
            <div className="nav-bar-dropdown-main-mobile"></div>
            <Link to={"/"} onClick={handleClick}>
              <p>{t("home")}</p>
            </Link>
            <HashLink to={"/#corp"} onClick={handleClick}>
              <p>{t("corprate")}</p>
            </HashLink>
            <HashLink to={"/#service"} onClick={handleClick}>
              <p>{t("services")}</p>
            </HashLink>
            <HashLink to={"/#fleet"} onClick={handleClick}>
              <p>{t("fleet")}</p>
            </HashLink>
            <HashLink to={"/#contact"} onClick={handleClick}>
              <p>{t("contact")} </p>
            </HashLink>
            <a
              href="https://vafaaero.com/"
              target="_blank"
              rel="noreferrer"
              onClick={handleClick}
            >
              <p>Vafa Uçuş Okulu</p>
            </a>
            <Link to={"/blog"} onClick={handleClick}>
              <p>Blog</p>
            </Link>
          </nav>
        </div>
      </nav>
    </div>
  );
}

export default Header;
