import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Servicecard(props) {
  const { t } = useTranslation();

  return (
    <div className="home-service-card">
      <div className="home-service-card-img">
        <img src={props.img} alt="" />
      </div>
      <h1>{props.title}</h1>
      <Link to={props.link}>
        <div className="home-service-card-button">
          <p> {t("review")} </p>
        </div>
      </Link>
    </div>
  );
}

export default Servicecard;
