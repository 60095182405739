import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import pic from "../../assets/services/img/pic.png";
import piclogo from "../../assets/core/logos/pic.png";

function Pic() {
  const { t } = useTranslation();

  return (
    <div className="services">
      <Header />
      <div className="services-main vafa ">
        <div className="services-img-con">
          <img src={pic} alt="" />
        </div>
        <div className="services-exp">
          <div className="services-exp-logo">
            <img src={piclogo} alt="" />
          </div>
          <div className="services-exp-con">
            <div className="services-vafa-header">
              <h1> {t("aoc_service")} </h1>
            </div>
            <div className="services-vafa-exp">
              <p>
                {t("pic1")}
                <br />
                {t("pic2")}
                <br />
                {t("pic3")}
              </p>
            </div>
            <div className="services-card">
              <p>Seval Sevdim Kamadan: {t("coordinator")} </p>
              <a href="mailto: ersin.teke@vizyonhavacilik.com">
                seval.sevdim@vizyonhavacilik.com.tr
              </a>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Pic;
