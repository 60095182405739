import React from "react";

import cloud from "../../assets/home/icon/cloud.png";
import lineplane from "../../assets/home/icon/lineplane.png";
import { useTranslation } from "react-i18next";

import youtube from "../../assets/icons/youtube.svg";
import instagram from "../../assets/icons/instagram.svg";
import linked from "../../assets/icons/linkedin.svg";
import whatsapp from "../../assets/icons/wplogo.png";

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer" id="contact">
      <div className="home-line-plane">
        <hr />
        <img src={lineplane} alt="" />
        <img className="cloud" src={cloud} alt="" />
      </div>
      <div className="footer-main">
        <div className="footer-header">
          <h1>{t("contact")}</h1>
        </div>
        <div className="footer-contact">
          <h2>{t("contact_us")}</h2>
          <hr />
        </div>
        <div className="footer-adress">
          <div className="footer-adress-con">
            <h3>Hangar</h3>
            <p>
              Esenboğa Havalimanı Özel Hangarlar Bölgesi 325/77 Nolu Hangar
              <br />
              Çubuk / ANKARA
            </p>
          </div>
          <div className="footer-adress-con">
            <h3> {t("head_office")} </h3>
            <p>
              Gazi Mustafa Kemal Bulvarı No:137 06570 <br />
              Çankaya / ANKARA
            </p>
          </div>
          <div className="footer-adress-con">
            <h3>{t("simulator_center")}</h3>
            <p>
            Gazi Mustafa Kemal Bulvarı No:137 06570 <br />
              Çankaya / ANKARA
            </p>
          </div>
          <div className="footer-adress-con">
            <h3>Tuzköy</h3>
            <p>
              Kapadokya Havaalanı Girişi 509000 <br /> Gülşehir / NEVŞEHİR
            </p>
          </div>
        </div>
        <div className="footer-info">
          <div>
            <a href="mailto:info@vizyonhavacilik.com.tr">
              info@vizyonhavacilik.com.tr
            </a>
            <a href="mailto:info@vafaaero.com">info@vafaaero.com </a>
          </div>
          <div>
            <a href="tel:03123844866">0 (312) 384 48 66</a>
            <a href="tel:4449592">444 95 92</a>
            <a href="tel:05398424649">0 539 842 46 49</a>
          </div>
        </div>
        <div className="footer-social">
          <a
            href="https://youtube.com/@vizyon.havacilik?si=YIG2SdH3GAoRRmz_"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/vizyon-havacilik/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://www.instagram.com/vizyonhavacilik/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linked} alt="" />
          </a>
          <a
            href="https://wa.me/905398424649"
            target="_blank"
            rel="noreferrer"
          >
            <img src={whatsapp} alt="" />
          </a>
        </div>
        {/* <div className="footer-rights">
          <h5>{t("rights")}</h5>
          
        </div> */}<br/>
        <div className="camFooter1" >
        <p>{t("rights1")}<a target="_blank" href="https://www.camgrup.org/">{t("rights2")}</a>{t("rights3")}</p>
        </div> <br/>
        <div className="camFooter2" >
      <p><a target="_blank" href="https://www.altinyapim.com/">{t("rights4")}</a> {t("rights5")}</p>
      </div>
      <br /><br />
      </div>
    </div>
  );
}

export default Footer;
