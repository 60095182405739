import React from 'react'

import Header from '../components/core/Header'
import Footer from '../components/core/Footer'
import WhatsAppButton from "../components/home/WhatsAppButton";
import Graduatescard from '../components/home/Graduatescard'

import line from "../assets/graduate/graduateline.png"
// import ozlemgence from "../assets/images/ozlemgence.jpg"
// import dogucan from "../assets/images/dogucan.jpg"
// import kaanmert from "../assets/images/kaanmert.jpg"
// import egemert from "../assets/images/egemert.jpg"



function gaduates() {
    const infos = [

        // fotoğraflı örnek
        // {
        //     id: 1,
        //     noun: "ÖZLEM GENCE",
        //     graduated: 2018,
        //     plane: "777 BOEING",
        //     photo: ozlemgence,
        //     exp: ""
        // },


        {
            id: 1,
            noun: "ÖMER KARADUMAN",
            plane: "VATAN JET",           
        },
        {
            id: 2,
            noun: "ONUR ALTINTAŞ",
            plane: "TÜRK HAVA YOLLARI",
        },
        {
            id: 3,
            noun: "CİHAN ÇAKIROĞLU",
            plane: "TÜRK HAVA YOLLARI",
        },
        {
            id: 4,
            noun: "UFUK KANAR",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 5,
            noun: "SÜLEYMAN ÖRSEL",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 6,
            noun: "BURAK YILMAZ",
            plane: "PEGASUS HAVA YOLLARI",           
        },
        {
            id: 7,
            noun: "CİHAN KESKİN",
            plane: "SUNEXPRESS",           
        },
        {
            id: 8,
            noun: "YUNUS EMRE MEMMİ",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 9,
            noun: "CAN İSPİR",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 10,
            noun: "GÜVEN GENÇ",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 11,
            noun: "BURCU MIHÇIOĞLU",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 12,
            noun: "DENİZ CANBERK ADIGÜZEL",
            plane: "PEGASUS HAVA YOLLARI",           
        },
        {
            id: 13,
            noun: "KAAN DEMİRCİOĞLU",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 14,
            noun: "SEZGİNCAN MAİLMAİL",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 15,
            noun: "AHMET YETİŞ",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 16,
            noun: "ORKAN  EGE  BOZGÜL",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 17,
            noun: "DAMLA NAZMİYE VURAL",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 18,
            noun: "MUSTAFA EKİZLER",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 19,
            noun: "ALPER BAŞ",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 20,
            noun: "MAHMUT SEÇKİN",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 21,
            noun: "ZİHNİ CAN İNMEZ",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 22,
            noun: "ERKAN GÖLCÜK",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 23,
            noun: "SİNAN ÖZ",
            plane: "SUNEXPRESS",           
        },
        {
            id: 24,
            noun: "OKAN DOĞAN UYAR",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 25,
            noun: "AHMET EMRE AYDINCAN",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 26,
            noun: "AHMET YETİŞ",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 27,
            noun: "BATUHAN AYHAN",
            plane: "SUNEXPRESS",           
        },
        {
            id: 28,
            noun: "NURETTİN YAŞAR",
            plane: "SUNEXPRESS",           
        },
        {
            id: 29,
            noun: "ÖMER DALKIR",
            plane: "PEGASUS HAVA YOLLARI",           
        },
        {
            id: 30,
            noun: "CANER ÖZATA",
            plane: "TÜRK HAVA YOLLARI",           
        },
        {
            id: 31,
            noun: "ABDUREZZAK BİLGİÇ",
            plane: "PEGASUS HAVA YOLLARI",           
        },
        {
            id: 32,
            noun: "GÜLSAH TUNÇ",
            plane: "TÜRK HAVA YOLLARI",
        },
        {
            id: 33,
            noun: "İLKE UÇAN",
            plane: "PEGASUS HAVA YOLLARI",
        },
        {
            id: 34,
            noun: "BERAT BERKE UÇAN",
            plane: "PEGASUS HAVA YOLLARI",
        },
        {
            id: 35,
            noun: "BEKIR ŞEN",
            plane: "TÜRK HAVA YOLLARI",
        },
        {
            id: 36,
            noun: "EGE BIROL",
            plane: "PEGASUS HAVA YOLLARI",
        }
        
        
    ]
    return (
        <div className='graduates'>
            <Header />
            <div className='graduates-main-con'>
                <div className='graduates-head'>
                    <h1>
                        MEZUN OLUP İŞE BAŞLAYAN ÖĞRENCİLERİMİZ
                    </h1>
                </div>
                <div className='graduates-body'>
                    <div className='graduate-left' >


                        {/* resim - uçak - yıl olan card modeli

                        <Graduatescard
                            noun={infos[0].noun}
                            img={infos[0].photo}
                            date={infos[0].graduated}
                            plane={infos[0].plane}
                            exp={infos[0].exp}
                        /> */}


                        <Graduatescard
                            noun={infos[0].noun}                        
                            plane={infos[0].plane}                           
                        />
                        <Graduatescard
                            noun={infos[2].noun}                        
                            plane={infos[2].plane}                           
                        />
                        <Graduatescard
                            noun={infos[4].noun}                        
                            plane={infos[4].plane}                           
                        />
                        <Graduatescard
                            noun={infos[6].noun}                        
                            plane={infos[6].plane}                           
                        />
                        <Graduatescard
                            noun={infos[8].noun}                        
                            plane={infos[8].plane}                           
                        />
                        <Graduatescard
                            noun={infos[10].noun}                        
                            plane={infos[10].plane}                           
                        />
                        <Graduatescard
                            noun={infos[12].noun}                        
                            plane={infos[12].plane}                           
                        />
                        <Graduatescard
                            noun={infos[14].noun}                        
                            plane={infos[14].plane}                           
                        />
                        <Graduatescard
                            noun={infos[16].noun}                        
                            plane={infos[16].plane}                           
                        />
                        <Graduatescard
                            noun={infos[18].noun}                        
                            plane={infos[18].plane}                           
                        />
                        <Graduatescard
                            noun={infos[20].noun}                        
                            plane={infos[20].plane}                           
                        />
                        <Graduatescard
                            noun={infos[22].noun}                        
                            plane={infos[22].plane}                           
                        />
                        <Graduatescard
                        noun={infos[24].noun}                        
                        plane={infos[24].plane}                           
                        />
                        <Graduatescard
                            noun={infos[26].noun}                        
                            plane={infos[26].plane}                           
                        />
                        <Graduatescard
                            noun={infos[28].noun}                        
                            plane={infos[28].plane}                           
                        />
                        <Graduatescard
                            noun={infos[30].noun}                        
                            plane={infos[30].plane}                           
                        />
                         <Graduatescard
                            noun={infos[32].noun}                        
                            plane={infos[32].plane}                           
                        />
                        <Graduatescard
                            noun={infos[34].noun}                        
                            plane={infos[34].plane}                           
                        />
                       

                    </div>
                    <div className='graduate-mid' ><img src={line} alt="çizgi" /></div>
                    <div className='graduate-right' >
                    <Graduatescard
                            noun={infos[1].noun}                        
                            plane={infos[1].plane}                           
                    />
                    <Graduatescard
                            noun={infos[3].noun}                        
                            plane={infos[3].plane}                           
                    />
                    <Graduatescard
                            noun={infos[5].noun}                        
                            plane={infos[5].plane}                           
                    />
                    <Graduatescard
                            noun={infos[7].noun}                        
                            plane={infos[7].plane}                           
                    />
                    <Graduatescard
                            noun={infos[9].noun}                        
                            plane={infos[9].plane}                           
                    />
                    <Graduatescard
                            noun={infos[11].noun}                        
                            plane={infos[11].plane}                           
                    />
                    <Graduatescard
                            noun={infos[13].noun}                        
                            plane={infos[13].plane}                           
                    />
                    <Graduatescard
                            noun={infos[15].noun}                        
                            plane={infos[15].plane}                           
                    />
                    <Graduatescard
                            noun={infos[17].noun}                        
                            plane={infos[17].plane}                           
                    />
                    <Graduatescard
                            noun={infos[19].noun}                        
                            plane={infos[19].plane}                           
                    />
                    <Graduatescard
                            noun={infos[21].noun}                        
                            plane={infos[21].plane}                           
                    />
                    <Graduatescard
                            noun={infos[23].noun}                        
                            plane={infos[23].plane}                           
                    />
                    <Graduatescard
                            noun={infos[25].noun}                        
                            plane={infos[25].plane}                           
                    />
                    <Graduatescard
                            noun={infos[27].noun}                        
                            plane={infos[27].plane}                           
                    />
                    <Graduatescard
                            noun={infos[29].noun}                        
                            plane={infos[29].plane}                           
                    />
                    <Graduatescard
                            noun={infos[31].noun}                        
                            plane={infos[31].plane}                           
                    />
                    <Graduatescard
                            noun={infos[33].noun}                        
                            plane={infos[33].plane}                           
                    />
                    <Graduatescard
                            noun={infos[35].noun}                        
                            plane={infos[35].plane}                           
                    />
                    

                    </div>
                </div>
            </div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default gaduates