import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import vafa from "../../assets/services/img/vafa.png";
import vafalogo from "../../assets/core/logos/vafa.png";
import arrow from "../../assets/services/icon/arrow.png";

function Vafa() {
  const { t } = useTranslation();

  return (
    <div className="services">
      <Header />
      <div className="services-main vafa">
        <div className="services-img-con">
          <img src={vafa} alt="" />
        </div>
        <div className="services-exp">
          <div className="services-exp-logo">
            <img src={vafalogo} alt="" />
          </div>
          <div className="services-exp-con">
            <div className="services-vafa-header">
              <h1>Vafa</h1>
              <p> {t("vafa_education")} </p>
              <img src={arrow} alt="" />
              <a href="https://www.vafaaero.com/">www.vafaaero.com</a>
            </div>
            <div className="services-vafa-exp">
              <p>
                {t("vafa1")}
                <br />
                {t("vafa2")}
                <br />
                <br />
                {t("vafa3")}
              </p>
            </div>
            <div className="services-card">
              <p>Ersin Teke: {t("trainer")} </p>
              <a href="mailto: ersin.teke@vizyonhavacilik.com">
                ersin.teke@vizyonhavacilik.com.tr
              </a>
            </div>
            <div className="services-card">
              <p>Gökhan Dadağlıoğlu: {t("fly_teacher")} </p>
              <a href="mailto: gokhan.dadaglioglu@vizyonhavacilik.com.tr ">
                gokhan.dadaglioglu@vizyonhavacilik.com.tr
              </a>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Vafa;
