import React from "react";
import { useTranslation } from "react-i18next";

import plane from "../../assets/fleet/cesnaimg.png";
import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Cesna() {
  const { t } = useTranslation();

  return (
    <div className="fleet">
      <Header />
      <div className="fleet-main">
        <h1>cessna 172 skyhawk</h1>
        <img src={plane} alt="tecnam p2008JC" />
        <p className='TopFeatureFl'>
                    {t("cesna10")} <br />
                    {t("cesna11")} <br />
                    {t("cesna12")} <br />
                    {t("cesna13")} <br />
                </p>
        <p>
          {t("cesna1")}
          <br />
          <br />
          {t("cesna2")}
          <br />
          <br />
          {t("cesna3")}
          <br />
          <br />
          {t("cesna4")}
          <br />
          <br />
          {t("cesna5")}
          <br />
          <br />
          {t("cesna6")}
          <br />
          <br />
          {t("cesna7")}
          <br />
          <br />
          {t("cesna8")}
          <br />
          <br />
          {t("cesna9")}
          <br />
          <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Cesna;
