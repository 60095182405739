import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Vision() {
  const { t } = useTranslation();

  return (
    <div className="vision">
      <Header />
      <div className="vision-con">
        <h1> {t("vision")} </h1>
        <div className="vision-exp-con">
          <div className="left-top"></div>
          <div className="left-bot"></div>
          <div className="right-top"></div>
          <div className="right-bot"></div>
          <p>
            {t("vision1")} <br />
            {t("vision2")} <br />
          </p>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Vision;
