import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import aoc from "../../assets/services/img/aoc.png";
import aoclogo from "../../assets/core/logos/aoc.png";

function Aoc() {
  const { t } = useTranslation();

  return (
    <div className="services">
      <Header />
      <div className="services-main aoc">
        <div className="services-img-con">
          <img src={aoc} alt="" />
        </div>
        <div className="services-exp">
          <div className="services-exp-logo">
            <img src={aoclogo} alt="" />
          </div>
          <div className="services-exp-con">
            <div className="services-vafa-header">
              <h1> {t("aoc_service")} </h1>
            </div>
            <div className="services-vafa-exp">
              <p>
                {t("aoc1")}
                <br />
                {t("aoc2")}
                <br />
                <br />
                {t("aoc3")}
                <br />
                <br />
                {t("aoc4")}
                <br />
                <br />
                {t("aoc5")}
                <br />
                {t("aoc6")}
              </p>
            </div>
            <div className="services-card">
              <p>Nuri Kocagil: {t("inspector")} </p>
              <a href="mailto: nuri.kocagil@vizyonhavacilik.com.tr ">
                nuri.kocagil@vizyonhavacilik.com.tr
              </a>
            </div>
            <div className="services-card">
              <p>Seval Sevdim Kamadan: {t("crew")} </p>
              <a href="mailto: seval.sevdim@vizyonhavacilik.com.tr">
                seval.sevdim@vizyonhavacilik.com.tr
              </a>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Aoc;
