import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import hangar from "../../assets/services/img/hangar.png";
import hangaricons from "../../assets/core/icons/hangar.png";

function Hangar() {
  const { t } = useTranslation();

  return (
    <div className="services">
      <Header />
      <div className="services-main han ">
        <div className="services-img-con">
          <img src={hangar} alt="" />
        </div>
        <div className="services-exp">
          <div className="services-exp-logo">
            <img src={hangaricons} alt="" />
          </div>
          <div className="services-exp-con">
            <div className="services-vafa-header">
              <h1> {t("hangarization")} </h1>
            </div>
            <div className="services-vafa-exp">
              <p>
                {t("hangar1")}
                <br />
                {t("hangar2")}
                <br />
                {t("hangar3")}
                <br />
                <br />
                {t("hangar4")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Hangar;
