import React from "react";

import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import blog from "../../assets/blog/img/blogthree.png";

function Blogthree() {
  const { t } = useTranslation();

  return (
    <div className="blog">
      <Header></Header>
      <div className="blog-main">
        <img src={blog} alt="" />
        <p>
          {t("blog_three1")} <br /> <br />
          {t("blog_three2")} <br /> <br />
          {t("blog_three3")} <br /> <br />
          {t("blog_three4")} <br /> <br />
          {t("blog_three5")} <br /> <br />
          {t("blog_three6")} <br /> <br />
          {t("blog_three7")} <br /> <br />
          {t("blog_three8")} <br /> <br />
          {t("blog_three9")} <br /> <br />
          {t("blog_three10")} <br /> <br />
          {t("blog_three11")} <br /> <br />
          {t("blog_three12")} <br /> <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Blogthree;
