import React from "react";
import { useTranslation } from "react-i18next";

import plane from "../../assets/fleet/arrowimg.png";
import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Arrow() {
  const { t } = useTranslation();

  return (
    <div className="fleet">
      <Header />
      <div className="fleet-main">
        <h1>Arrow Copter XC20</h1>
        <img src={plane} alt="Arrow Copter XC20" />
        
        <p>
          {t("arrow1")}
          <br />
          <br />
          {t("arrow2")}
          <br />
          <br />
          {t("arrow3")}
          <br />
          <br />
          {t("arrow4")}
          <br />
          <br />
          {t("arrow5")}
          <br />
          <br />
          {t("arrow6")}
          <br />
          <br />
          {t("arrow7")}
          <br />
          <br />
          {t("arrow8")}
          <br />
          <br />
          {t("arrow9")}
          <br />
          <br />
          {t("arrow10")}
          <br />
          <br />
          {t("arrow11")}
          <br />
          <br />
          {t("arrow12")}
          <br />
          <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}
export default Arrow;
