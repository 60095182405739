import React from "react";

import plane from "../../assets/fleet/piperimg.png";
import { useTranslation } from "react-i18next";
import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Piper() {
  const { t } = useTranslation();

  return (
    <div className="fleet">
      <Header />
      <div className="fleet-main">
        <h1>Piper arrow pA-28R</h1>
        <img src={plane} alt="Piper arrow pA-28R" />
        <p className='TopFeatureFl'>
                    {t("piper9")} <br />
                    {t("piper8")} <br />
                    {t("piper7")} <br />
                    {t("piper6")} <br />
                </p>
        <p>
          {t("piper1")}
          <br />
          <br />
          {t("piper2")}
          <br />
          <br />
          {t("piper3")}
          <br />
          <br />
          {t("piper4")}
          <br />
          <br />
          {t("piper5")}
          <br />
          <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Piper;
