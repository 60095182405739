import React from "react";
import { useTranslation } from "react-i18next";

import plane from "../../assets/fleet/trixyimg.png";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Trixy() {
  const { t } = useTranslation();

  return (
    <div className="fleet">
      <Header />
      <div className="fleet-main">
        <h1>uL gyrocopter trixy</h1>
        <img src={plane} alt="uL gyrocopter trixy" />
        <p>
          {t("trixy1")}
          <br />
          <br />
          {t("trixy2")}
          <br />
          <br />
          {t("trixy3")}
          <br />
          <br />
          {t("trixy4")}
          <br />
          <br />
          {t("trixy5")}
          <br />
          <br />
          {t("trixy6")}
          <br />
          <br />
          {t("trixy7")}
          <br />
          <br />
          {t("trixy8")}
          <br />
          <br />
          {t("trixy9")}
          <br />
          <br />
          {t("trixy10")}
          <br />
          <br />
          {t("trixy11")}
          <br />
          <br />
          {t("trixy12")}
          <br />
          <br />
          {t("trixy13")}
          <br />
          <br />
          {t("trixy14")}
          <br />
          <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Trixy;
