import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Filocardright(props) {
  const { t } = useTranslation();
  return (
    <div className="filo-right-card">
      <div className="filo-card-exp">
        <p>{props.model}</p>
        <Link to={props.link}>
          <div className="filo-card-button">
            <p>{t("review")}</p>
          </div>
        </Link>
      </div>
      <img src={props.img} alt="" />
    </div>
  );
}

export default Filocardright;
