import React from "react";
import { useTranslation } from "react-i18next";

import plane from "../../assets/fleet/guimballimg.png";
import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Guimball() {
  const { t } = useTranslation();

  return (
    <div className="fleet">
      <Header />
      <div className="fleet-main">
        <h1>guimbal cabri g2</h1>
        <img src={plane} alt="guimbal cabri g2" />
        <p>
          {t("guimball1")}
          <br />
          <br />
          {t("guimball2")}
          <br />
          <br />
          {t("guimball3")}
          <br />
          <br />
          {t("guimball4")}
          <br />
          <br />
          {t("guimball5")}
          <br />
          <br />
          {t("guimball6")}
          <br />
          <br />
          {t("guimball7")}
          <br />
          <br />
          {t("guimball8")}
          <br />
          <br />
          {t("guimball9")}
          <br />
          <br />
          {t("guimball10")}
          <br />
          <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Guimball;
