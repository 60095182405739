import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Whyus() {
  const { t } = useTranslation();

  return (
    <div className="whyus">
      <Header />
      <div className="whyus-con">
        <h1> {t("why_us")} </h1>
        <div className="whyus-exp-con">
          <div className="left-top"></div>
          <div className="left-bot"></div>
          <div className="right-top"></div>
          <div className="right-bot"></div>
          <p>
            {t("why_us1")} <br />
            <br />
            {t("why_us2")} <br />
            <br />
            {t("why_us3")} <br />
            <br />
            {t("why_us4")} <br />
            <br />
            {t("why_us5")} <br />
            <br />
            {t("why_us6")} <br />
            <br />
            {t("why_us7")} <br />
            <br />
            {t("why_us8")} <br />
            <br />
            {t("why_us9")} <br />
            <br />
          </p>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Whyus;
