import React from "react";

import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import blog from "../../assets/blog/img/blogtwo.jpg";

function Blogtwo() {
  const { t } = useTranslation();

  return (
    <div className="blog">
      <Header></Header>
      <div className="blog-main">
        <img src={blog} alt="" />
        <p>
          {t("blog_two1")} <br /> <br />
          {t("blog_two2")} <br /> <br />
          {t("blog_two3")} <br /> <br />
          {t("blog_two4")} <br /> <br />
        </p>
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Blogtwo;
