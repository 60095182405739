import React from "react";

import { useTranslation } from "react-i18next";

import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

import blog from "../../assets/blog/img/icao.jpeg";
import img1 from "../../assets/blog/img/1.jpeg"
import img2 from "../../assets/blog/img/2.jpeg"
import img3 from "../../assets/blog/img/3.jpeg"

function Blogtwo() {
  const { t } = useTranslation();

  return (
    <div className="blog">
      <Header></Header>
      <div className="blog-main">
        <img src={blog} alt="" />
        
         <img src={img1} alt="" /> <br />
         <img src={img2} alt="" /> <br />
         <img src={img3} alt="" /> <br />
        
      </div>
      <WhatsAppButton />
    </div>
  );
}

export default Blogtwo;
