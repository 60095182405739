import React from 'react';
import wplogo from "../../assets/icons/wplogo.png"


function WhatsAppButton() {
  return (
    <a target='_blank' href="https://wa.me/905398424649" className="whatsapp-button">
      {/* WhatsApp ikonu veya butonu burada yer alabilir */}
      <img src={wplogo} alt="WhatsApp" />
      
    </a>
  );
}

export default WhatsAppButton;
